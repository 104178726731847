* {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
}

.shapeParent {
  position: relative;
}

.shape {
  position: absolute;
  left: 0;
  background-color: #067bc2;
  height: 30px;
  width: 8px;
  border-radius: 2px;
}